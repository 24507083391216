.main {
  padding: 30px 0;
  background: #f2f3f5;
}

.main__container {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.browser {
  width: 400px;
  margin-right: 30px;
}

.pokemon-list {
  display: grid;
  row-gap: 5px;
}

.wrapper {
  flex-grow: 1;
}

.wrapper--grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}
